@import "../variables.module";

.container {
    // height: 300px;
    width: $modalWidth;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    @media (max-width: $breakPointTwo) {
        width: 100%;
    }

    .top-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo-container {
            width: 49%;
            height: 38px;
        }
        h1 {
            padding-bottom: $padding;
            padding-top: $padding * 2;
            font-size: 20px;
        }
        p{
            text-align: center;
            padding-bottom: $padding;
            color: $fontColorTwo;
            font-size: 14px;
        }
    }
    p {
        font-size: $fontSizeFour;
    }

}



@media (max-width: $breakpoint-one) {
 
}

@media (prefers-color-scheme: dark) {
  
}
