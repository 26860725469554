@import "../variables.module";

.container {
  height: 95vh;
  padding: 0;
  max-height: 600px;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;

  .title {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 340px;

    padding-bottom: 2rem;
  }
}
.iconContainer {
  display: flex;
  width: 203px;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
}

.inputs {
  display: flex;
  gap: 0.5rem;
}

.input {
  width: 52.575px;
  height: 65.718px;
  text-align: center;
  font-size: 1.25rem;
  border-radius: 7.886px;
  border: 1.314px solid #5e679e;
  background: radial-gradient(
      76.33% 100% at 50% 100%,
      #262c52 0%,
      rgba(38, 44, 82, 0.1) 100%
    ),
    rgba(38, 44, 82, 0.5);
  caret-color: rgba(232, 229, 255, 0.75);
  color: #fff;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:focus {
    border: 1.517px solid #2099ff;

    outline: none;
  }

  @media (max-width: 375px) {
    width: 42.575px;
    height: 55.718px;
  }
}

.errorInput {
  border: 1.517px solid #ed4343;

  &:focus {
    border: 1.517px solid #ed4343;
  }
}

.authenticatedInput {
  border-radius: 7.886px;
  border: 1.314px solid #16d94c;
  background: radial-gradient(
      76.33% 100% at 50% 100%,
      #262c52 0%,
      rgba(38, 44, 82, 0.1) 100%
    ),
    rgba(38, 82, 50, 0.5);
}

.codeText {
  color: rgba(232, 229, 255, 0.75);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 0 1.4rem;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  min-height: 350px;
  justify-content: space-between;
  padding-top: 2rem;
}

.bottomText {
  color: $sub-heading-color;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 12px;

  .supportLink {
    color: $primary-stroke-color;
    display: inline-block;
    margin-left: 4px;

    cursor: pointer;
  }
}

.hiddenTextarea {
  position: absolute;
  opacity: 1;
  pointer-events: none;
  height: 0;
  width: 0;
  z-index: -1;
}
