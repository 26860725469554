@import "@styles/variables.module.scss";

// >>>>>>>>> do not add background here, do it in the child components

.container {
  width: 100%;
  overflow: hidden;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // use the children for this
    // padding: 15px 20px;
    cursor: pointer;
    border-radius: $radius;
    color: white;
  }

  &.open {
    .head {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .body {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    overflow: hidden;

    &:not(.open) {
      height: 0;
      border-color: transparent;
    }
  }

  &.padding {
    .body {
      > div {
        padding: 15px 20px;
      }
    }
  }

  &.second {
    &.open .head {
      border-bottom-color: transparent;
    }

    .head {
      border: 1px solid rgba(255, 255, 255, 0.05);
    }

    .body {
      border: 1px solid transparent;
    }

    &.open .body {
      border-color: rgba(255, 255, 255, 0.05);
      border-top-color: transparent;
    }
  }

  &.third {
    margin-top: 40px;

    > div {
      font-size: 14px;
      line-height: normal;

      h2:first-child {
        margin-top: 0;
      }
    }

    > div:nth-child(1) {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: linear-gradient(
        180deg,
        rgba(124, 131, 177, 0.15) 0%,
        rgba(94, 103, 158, 0.15) 100%
      );
      padding: 16px 20px;
    }

    > div:nth-child(2) {
      background: $editBackground;
    }
  }
}
