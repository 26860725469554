@import "../variables.module";

.promo-terms-container {
  background-color: $secondaryBackgroundColor;

  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // background-color: $dark-one;
  width: 470px;

  @media (max-width: $breakPointTwo) {
    width: 100%;
  }

  // height: 300px;

  .promo-terms {
    overflow-y: auto;

    right: 0px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    // padding-bottom: 30px;

    @include scrollbars(5px, $btnFill, transparent);
    h2 {
      padding-bottom: 20px;
    }
    p {
      color: $fontColorTwo;
      font-size: 13px;
    //   padding-bottom: 20px;
    }
    .promo-warning {
      font-size: 13px;
      color: #e94c4c;
      margin: 10px 0px 20px;

      span {
        margin: 0px 3px;
        font-size: 13px;
        color: #e94c4c;
        font-weight: 700;
        svg {
            transform: translateY(2px);
        }
      }
    }
  }

  .promo-terms-buttons {
    display: flex;
    gap: 20px;
    max-height: 40px;
    width: 100%;
    justify-content: space-between;


  }
}



.promo-terms-cancel-container {
  background-color: $secondaryBackgroundColor;

  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // background-color: $dark-one;
  width: 470px;

  @media (max-width: $breakPointTwo) {
    width: 100%;
  }

  // height: 300px;

  .promo-terms-cancel {
    overflow-y: auto;

    right: 0px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    // padding-bottom: 30px;

    @include scrollbars(5px, $btnFill, transparent);
    h2 {
      padding-bottom: 20px;
    }
    p {
      color: $fontColorTwo;
      font-size: 13px;
    }
    .promo-warning-cancel {
      font-size: 13px;
      color: #e94c4c;
      margin: 10px 0px 20px;
      span {
        margin: 0px 3px;
        font-size: 13px;
        color: #e94c4c;
        font-weight: 700;
        svg {
            transform: translateY(2px);
        }
      }
    }
  }

  .promo-terms-buttons-cancel {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
  }
}