@import "../variables.module";

.countdownLink {
    padding: 0 32px;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 12px;
    display: inline-flex;
  
    p {
        color: rgba(232, 229, 255, 0.75);
        display: inline-block;
        margin-left: 4px;
  
    }
    
    .resendLink {
        color: $primary-stroke-color;
        cursor: pointer;
    }

    .loadingContainer {
        display: inline-flex;
        margin-left: 8px;

        & > div {
            padding-top: 8px;
        }
    }

    h3 {
        display: inline-block;
        width: 20px;
        text-align: center;
    }

}
.countdownText {
    text-wrap: nowrap;
    display: inline-block;
    width: 50px;
    text-align: center;
    outline: 1px solid red;
}