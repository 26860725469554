@import "../variables.module";

.fairTest {
  width: 100px;
  border: 1px solid #fff;
}

.game-overview {
  background-color: $thirdBackground;
  border-radius: $radius;
  padding: $padding;
}

.game-parameters {
  display: flex;
}

.result-container {
  width: 100%;
  // border-top: 1px solid $borderColor;
  padding-top: $padding;
}
.form-container {
  h3 {
    padding-bottom: $padding;
  }
}
.item-row {
  display: flex;

  @media (max-width: $breakPointThree) {
    flex-direction: column;
    gap: 20px;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}
.item-content {
  padding-bottom: 12px;
}
.detail-item {
  // width: 30%;
  flex: 1 1 33%;
  text-align: center;
  font-family: $boldFont;

  &.full-width {
    flex: 1 1 100%;

    width: 100%;
  }
  span {
    color: rgba(232, 229, 255, 0.75);

    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .value {
    position: relative;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 75px;
    background: radial-gradient(
        76.33% 100% at 50% 100%,
        #262c52 0%,
        rgba(38, 44, 82, 0.1) 100%
      ),
      rgba(38, 44, 82, 0.5);
    justify-content: center;
    span {
      font-size: 14px;
      color: $fontColor;
    }
    &::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 43px;
      top: calc(50% - 21.5px);
      display: none;
      background: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 26.5%,
        rgba(255, 255, 255, 0.1) 75%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  @media (min-width: $breakPointThree) {
    &:first-child {
      .value {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        &::after {
          display: block;
          right: 0;
        }
      }
    }
    &:last-child {
      .value {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        &::after {
          display: block;
          left: 0;
        }
      }
    }
  }
}

.container {
  width: 450px;
  // background-color: $dark-one;
  border-radius: 10px;

  @media (max-width: $breakPointTwo) {
    width: 100%;
  }

  h2 {
    // text-transform: capitalize;
    padding-bottom: 0;
  }
  .user-display {
    width: 100%;
    border-radius: $radius;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    border: 1px solid $borderColor;
    gap: 14px;
    @media (max-width: $breakPointThree) {
      padding: 10px;
      flex-direction: column;
    }
    .right {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 5px 5px 5px;
      h2 {
        font-size: 18px;
        padding-bottom: 5px;
      }
      .game-data {
        h2 {
          color: rgba(232, 229, 255, 0.8);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
          padding: 0;
        }
        p {
          color: #5b628c;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .user-data {
        font-size: 13px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        p {
          color: rgba(232, 229, 255, 0.75);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          span {
            color: rgba(232, 229, 255, 0.5);
            font-weight: 500;
          }
        }
        .user-name {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 3px;

          p {
            color: rgba(232, 229, 255, 0.5);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          span {
            color: rgba(232, 229, 255, 0.8);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        span {
          color: $fontColorTwo;
        }
      }
    }
    .left {
      border-radius: 6px;
      min-width: 100px;
      max-width: 140px;
      flex: 1;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      aspect-ratio: $card-ar;
      transition:
        transform 300ms ease-in-out,
        box-shadow 300ms ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100% !important;
        object-fit: cover;
      }
    }
  }
  .logo-container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    margin-top: 20px;
    hr {
      background-color: rgba(255, 255, 255, 0.05);
      width: 100%;
      height: 2px;
    }
  }
  .overview-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $padding;

    .overview-section {
      border-radius: $radius;
      background-color: $btnFill;
      height: 71px;
      width: 100%;
      display: flex;
      align-items: center;

      .icon {
        width: 35px;
        margin-left: $padding;
        margin-right: 15px;

        svg path {
          fill: $gold;
        }
      }
      .text-detail {
        h3 {
          color: $fontColorTwo;
          font-size: $fontSizeThree;
          padding-bottom: 5px;
        }
        h2 {
          font-size: $fontSizeTwo;
          color: $gold;
        }
        .value {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
  .footer {
    margin-top: 20px;
    a {
      margin-top: 20px;
    }
    p {
      color: rgba(232, 229, 255, 0.5);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: inline;
      margin-right: 8px;
    }
  }
  .notice-message {
    color: #e8e5ff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */

    b {
      color: #fff;
      font-weight: 500;
    }
  }
}

.rotate-seed-section {
  display: flex;
  align-items: center;
  gap: 15px;
  span {
    width: 50%;
    font-size: 12px;
    font-family: $boldFont;
    color: $fontColorTwo;
    flex: 1 1 100%;
    line-height: 1.3em;
  }
}

.form-container {
  // padding: $Dmw/2;
  //width: 400px;
  // margin: $Dmw;
}

.nav {
}

@media (max-width: $breakpoint-one) {
}

@media (prefers-color-scheme: dark) {
}
