@import "../variables.module.scss";

.redeem-gift-card-modal-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  // h2 {
  //   color: #fff;
  //   font-size: 20px;
  //   font-style: normal;
  //   // font-weight: 700;
  // }

  p {
    color: #7179a5;
    font-size: 13px;
    font-style: normal;
    // font-weight: 600;
  }

  .redeem-gift-card-input-container {
    position: relative;
    margin-bottom: -15px;
    margin-top: 15px;
    .digit-code {
      font-size: 13px;
      color: #7179a5;
    }
  }
  .redeem-gift-card-description-container {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    color: #7179a5;
    font-size: 13px;
    font-style: normal;
    width: 100%;
    margin-top: 5px;
    cursor: pointer;
    align-self: center;
    transition: color 0.2s;

    svg {
      path {
        transition: fill 0.2s;
      }
    }
    &:hover {
      color: #949bc2;
      svg {
        fill: #949bc2;
        path {
          fill: #949bc2;
        }
      }
    }
  }
}

.gift-card-modal-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  .star-icon-one {
    position: absolute;
    right: -24px;
    top: 137px;
  }
  .star-icon-two {
    position: absolute;
    top: 280px;
    left: -32px;
  }
  .star-icon-three {
    position: absolute;
    bottom: 225px;
    right: 4px;
  }

  p {
    color: #7179a5;
    font-size: 13px;
    font-style: normal;
    width: fit-content;
    // font-weight: 600;
  }

  .gift-cards-container {
    p {
      color: #e8e5ff;
      font-size: 13px;
      margin-bottom: 15px;
    }
    .gift-cards {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .card {
        padding: 5px 12px;

        // width: 173px;
        cursor: pointer;
        background: linear-gradient(99deg, #09122c 0%, #191f3b 100%);
        border-radius: 13px;
        &:hover {
          background: linear-gradient(99deg, #09122c 0%, #171c34 100%);
        }
        .gift-card-content {
          display: flex;
          justify-content: space-between;
          .gift-card-content-left {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            svg {
              width: 50px;

              @media (max-width: 768px) {
                width: 40px;
              }
            }
            span {
              color: #e8e5ff;
              font-size: 11px;
              white-space: nowrap;
            }
          }
          .gift-card-content-right {
            display: flex;
            align-items: center;
            gap: 6px;
            color: #e8e5ff;

            font-size: 13px;
          }
        }
        &.selected {
        }
      }
      p {
        font-size: 13px;
        margin-top: 20px;
        text-align: center;
        width: 100%;
      }
    }
  }
  .gift-card-payment-methods-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    margin: 5px 0px 15px;
  }

  .gift-card-button-container {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    color: #7179a5;
    font-size: 13px;
    font-style: normal;
    width: 100%;
    margin-top: 5px;
    cursor: pointer;
    align-self: center;
    line-height: 17px;
    transition: color 0.2s;
    svg {
      path {
        transition: fill 0.2s;
      }
    }
    &:hover {
      color: #949bc2;
      svg {
        fill: #949bc2;
        path {
          fill: #949bc2;
        }
      }
    }
  }
  .gift-card-description-container {
    padding-top: 20px;
    border-top: 1px solid #212744;
    color: #7179a5;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    margin-top: 5px;
    align-self: center;
    line-height: 17px;
    a {
      color: #949bc2;
    }
  }

  .purchase-icon {
    margin-left: 7px;
    transform: translateY(1px);
  }
}

.digit-code-container {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
}
