@import "../variables.module";

@mixin transition($property, $duration, $timing) {
  transition: $property $duration $timing;
}

.auth-container {
  max-width: 900px;
  min-height: 700px;
  display: flex;
  height: 100%;

  @media (max-width: 910px) {
    width: 100%;
  }
  @media (max-width: $modalBreakPointOne) {
    width: 100%;
    height: 100%;
  }

  .left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 445px;
    flex-shrink: 0;
    position: relative;

    @media (max-width: 910px) {
      display: none;
    }
  }

  .right-container {
    width: 450px;
    padding: 32px 24px;

    @media (max-width: 910px) {
      width: 450px;
      max-width: 450px;
      padding: 12px;
      margin: auto;
    }
    @media (max-width: $modalBreakPointOne) {
      width: -webkit-fill-available;
      height: 100%;
    }

    .auth-bottom-content {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 965px) {
    .left-container {
      display: none;
    }
  }

  &.active-two-factor {
    transition: width 200ms $easeInOut3;

    @media (max-width: 880px) {
      width: 100%;
    }
  }
  h2 {
    padding-bottom: 0;
  }

  .alt-signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      height: 14px;
      font-size: 13px;
      color: rgba(232, 229, 255, 0.5);
      overflow: hidden;
      text-align: center;
      width: 100%;
      font-weight: 400;

      &:before,
      &:after {
        background-color: hsla(0, 0%, 95%, 0.1);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        top: -1px;
        vertical-align: middle;
        width: 50%;
      }
      &:after {
        left: 0.5em;
        margin-right: -50%;
      }
      &:before {
        right: 0.5em;
        margin-left: -50%;
      }
    }

    .btn-content {
      gap: 10px;
      display: flex;
      padding: 1.5rem 0;
      opacity: 70%;

      button {
        background-color: #262c52;
      }
    }
  }
}

.terms-container {
  z-index: 1;
  position: absolute;
  bottom: 10px;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;

  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  .terms-conditions-logo {
    color: rgba(255, 255, 255);
    display: inline-block;
    line-height: 1.4;
    font-size: 12px;
    padding-left: 3px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      text-decoration-line: underline;
    }
    div {
      span {
        cursor: pointer;
        transition: color 0.2s;
        padding: 0;
        text-decoration-thickness: 0.05px;

        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
}

.particles-background {
  position: absolute;
  z-index: 1;
  max-width: 450px;
  overflow: hidden;
  opacity: 0.2;
  top: 0;
}

.left-logo {
  position: absolute;
  z-index: 1;
  top: 7rem;

  // outline: 1px solid red;
}
.ready-to-play {
  position: absolute;
  top: 22rem;
  margin-left: auto;
  padding-top: 1rem;
  width: 450px;
  text-align: center;

  span {
    color: #6fd8f8;
  }
}

.bottom-logo {
  position: absolute;
  top: 37rem;
  left: 11rem;
  color: #fff;
  z-index: 1;
}

.twoFactor {
  padding-top: 20px;

  p {
    line-height: 1.3em;
    font-size: 12px;
    color: $fontColorTwo;
    padding-bottom: 20px;
  }

  h3 {
    padding: 1rem 0;
    line-height: 1.5em;
  }

  @media (max-width: 880px) {
    padding: 1.6rem 0.4rem;
    width: 100%;
    margin: auto;
  }
}

.recover-login {
  padding-top: 1rem;
  button {
    margin-left: auto;
  }
}

.recover-button {
  padding-top: 1rem;
}

.referal-code-message {
  font-size: 13px;
  padding-bottom: 20px;

  p {
    color: $fontColorTwo;
    span {
      color: $sub-heading-color;
    }
  }
}

.referral-dropdown {
  display: inline-flex;
  font-size: 12px;
  text-wrap: nowrap;
}

.checkbox-container {
  display: flex;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    position: relative;
    padding-left: 35px;
    margin-top: 5px;
    color: $fontColorTwo;
    line-height: 1.4;
    font-size: 13px;
    @include transition(all, 0.3s, ease);
  }

  label {
    cursor: pointer;
    padding: 0;
  }

  input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 1px solid $fontColorTwo;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;

    @include transition(all, 0.3s, ease);
  }

  input[type="checkbox"]:checked + label::before {
    background-color: $fontColorTwo;
    border-color: $fontColorFour;
  }

  input[type="checkbox"]:checked + label::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 36%;
    transform: translateY(-50%) rotate(45deg);
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
  }

  input[type="checkbox"]:focus + label::before {
    border-color: $fontColorFour;
  }
}

.switch-auth-type {
  text-align: center;
  font-size: 13px;
  color: rgba(232, 229, 255, 0.5);

  a {
    display: inline-block;
    padding-left: 4px;
  }
}
