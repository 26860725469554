@import "../variables.module";

.container {
  width: 431px;
  // background-color: $dark-one;
  border-radius: 10px;

  @media (max-width: $breakPointTwo) {
    width: 100%;
  }
  h2 {
    text-transform: capitalize;
    padding-bottom: 0;
  }

  h3 {
    padding-bottom: 10px;
    font-size: 15px;
  }
  .rotation-section {
    border-top: 1px solid $btnStroke;
    padding-top: 20px;
  }
}
.fair-changes-notice {
  border-radius: $radius;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  background-color: $primaryBackgroundColor;
  p {
    color: $primaryFontColor;
    font-size: 13px;
  }
}
.fairResult-container {
  .result-display {
    border-radius: $radius;
    background-color: $btnFillThree;
    border: 1px solid $btnBorderTwo;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
    margin-bottom: $padding;
    gap: 10px;
    span {
      font-family: $boldFont;
      font-size: 18px;
      color: $fontColor;
    }
  }
}

.rotate-seed-section {
  display: flex;
  align-items: center;
  gap: 15px;
  span {
    line-height: 1.3em;
    width: 50%;
    font-size: 12px;
    font-family: $boldFont;
    color: $fontColorTwo;
    flex: 1 1 100%;
  }
}

.double-down-results {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
}

.itemResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid $fontColorTwo;
  gap: 20px;
  // border-radius: $radius;
  overflow: hidden;

  .item-values {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 70%;
  }
  .multi-item-icon {
    span {
      padding: 10px;
      font-size: 18px;
    }
  }
}

.tieResult {
  display: flex;
  flex-direction: column;
  // align-items: center;
  border: 1px solid #282f53;
  border-radius: 6px;
  gap: 20px;
  padding: 10px;
  // border-radius: $radius;
  overflow: hidden;
  margin-bottom: 20px;
  .item-values {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 70%;
  }
  .multi-item-icon {
    span {
      padding: 10px;
      font-size: 18px;
    }
  }
}

.itemInfo {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
}
.item-value {
  display: flex;
  gap: 5px;
  align-items: center;
}
.item-heading {
  font-size: 15px;
  font-family: $boldFont;
  color: $fontColor;
}
.case-details-container {
  display: flex;
  gap: 10px;

  padding-top: 10px;
  padding-bottom: 10px;
}
.result-color {
  color: #16d94c;
}
.itemIcon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.rangeBar {
  position: relative;
  width: 100%;
  height: 5px;
  background-color: #ffa000;
  margin-top: 10px;
}

.rangeHighlight {
  position: absolute;
  height: 100%;
  background-color: #16d94c;
}

.rollResultIndicator {
  position: absolute;
  color: #16d94c;
  transform: translate(
    -50%,
    -13px
  ); /* Center the arrow and position it above the bar */
}

.battle-result-container {
  h2 {
    margin-bottom: 10px;
  }

  .player-multi-array {
    margin-top: 20px;
    .player-multi {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    ol {
      padding-left: 20px;
      li {
        color: #fff;
        font-size: 10px;
      }
    }
  }

  .round-select-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    margin-top: 10px;
    margin-bottom: 10px;
  }
  .result-items-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;

    .items-display {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border: 1px solid $btnStroke;
      border-radius: $radius;
      padding: 10px;
    }
  }
}
.row-item {
  display: flex;
  gap: 10px;
}
@media (max-width: $breakpoint-one) {
}

@media (prefers-color-scheme: dark) {
}
