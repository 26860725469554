@import "../variables.module";

.notice {
  color: $orange;
  padding: 20px;
  border-radius: $radius;
  margin-bottom: 30px;

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;

  &--warning {
    background: rgba($color: $orange, $alpha: 0.1);
    border: 1px solid rgba($color: $orange, $alpha: 0.2);
  }

  &--neutral {
    background: rgba($color: #fff, $alpha: 0.1);
    border: 1px solid rgba($color: #fff, $alpha: 0.2);
  }
}
