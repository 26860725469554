@import "../variables.module";

.lock-balance {
  pointer-events: none;
  opacity: 0.8;
  transition: opacity 200ms $easeInOut3;
}

.balance-quick-select-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 15px;

  .quick-select-heading {
    width: 100%;
    text-align: left;
    padding: 10px 15px;
    padding-top: 0px;
    span {
      font-size: 12px;
      color: $primaryFontColor;
    }
  }
}

.base-balance-display {
  display: flex;
  align-content: center;

  gap: 5px;
  color: $primaryFontColor;
  font-size: 13px;
  border: 2px solid $btn-stroke-color;
  background-color: $btn-fill-color;
  align-items: center;
  border-radius: 6px;
  padding: 10px 20px 10px 15px;
  min-width: 130px;
  height: 100%;

  svg {
    width: 14px;
  }

  &.center {
    justify-content: center;
  }
}

.main-balance-display {
  display: flex;
  gap: 5px;
  align-items: center;
}

.balance-drop-item {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;

  .balance-name {
    font-size: 12px;
    color: #e8e5ff;
    transition: color 200ms $easeInOut3;

    svg path {
      transition: fill 200ms $easeInOut3;
    }
    &__active {
      color: #7dd934;

      svg path {
        fill: #7dd934;
      }
    }
  }

  svg {
    transform: translateY(1px);
  }
}
.radio-icon {
  border-radius: 50%;
  display: flex;
  width: 20px;
  height: 20px;
  right: 0;
  top: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  border: 1px solid transparent;
  background-color: #0f13286a;

  transition: border 200ms $easeInOut3;

  .radio-icon-inner {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $green;
    scale: 0;
    transition: scale 200ms $easeInOut3;
  }

  &__active {
    border: 1px solid $green;
    .radio-icon-inner {
      scale: 1;
    }
  }
}

.balance-select-container {
  display: flex;
  flex-direction: column;
  background-color: #262c52;
  position: relative;
  z-index: 5008;

  .loader-container {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    // width: 50px;
    // height: 50px;
    z-index: 5;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    // border: 1px solid #fff;
    background-color: #262c5247;
    backdrop-filter: blur(2px);
    transition: opacity 200ms $easeInOut3;
    &__show {
      opacity: 1;
    }
  }
}

.balance-select {
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  &.has-promo {
    pointer-events: auto;
  }

  .vault-balance-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-top: 5px;
    padding-bottom: 0px;
    .desc {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    span {
      color: #e8e5ff;
      font-size: 12px;
    }
  }
}

.inPlay-balance-dropdown-container {
  display: flex;
  gap: 5px;
  align-items: center;

  div {
    font-size: 14px;
    color: #dedaeb;
  }
  svg {
    transform: translateY(-1px);
  }
}

.balance-drop-down-footer {
  padding: 4px;

  .balance-drop-down-footer-line {
    width: 100%;
    height: 1px;
    border-top: 1px solid #ffffff12;
  }
  .current-currency-displayed {
    display: flex;
    gap: 5px;

    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: $primaryFontColor;
  }
}

@media (max-width: $breakpoint-one) {
}

@media (prefers-color-scheme: dark) {
}
