@import "../variables.module";

.modal-spinner {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0px;
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: $fifthBackground;
  height: 100%;
  z-index: 5;
  transition: opacity 300ms $easeInOut3;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}
