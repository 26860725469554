@import "../variables.module";

.drop-down-container {
  opacity: 0;
  pointer-events: none;

  // position: fixed;
  width: 155px;
  // width: auto;
  overflow: hidden;
  border-radius: $radius;
  background-color: $btnFill;
  z-index: 5002;

  transition: opacity 0.2s $easeInOut3;
  &.emote-select {
    width: 220px;
    height: 200px;
    overflow-y: scroll;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -webkit-box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 8%);
    box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 8%);
    border: 1px solid #1a1f3d;
    background: #151a33;

    @include scrollbars(4px, #394067, transparent);
  }

  &.rewards-dropdown {
    width: 330px;
    background-color: #1c213d;
    box-shadow:
      rgba(0, 0, 0, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

    transform: translateX(10px);
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }
  &.lang-dropdown {
    background: #171d3b;
    border-radius: 6px;
    border: 1px solid #262b42;
    background: rgba(37, 42, 71, 0.3);
    backdrop-filter: blur(25px);
    width: 100%;
    max-width: 198px;
  }
  &.profile-dropdown {
    box-shadow:
      rgba(0, 0, 0, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #262c52;
    padding: 8px;

    max-width: 330px;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &.balance-dropdown {
    width: 270px;
  }

  &.crypto-dropdown,
  &.network-dropdown {
    width: 100%;
    background: #262c52;
    box-shadow:
      rgba(0, 0, 0, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px;
    border: 2px solid #282f58;
    padding: 10px;
    padding-bottom: 0px;
    max-height: 250px;
    overflow-y: auto;
  }

  &.network-dropdown {
    padding: 0px;
  }
  &.auto-width {
    width: auto;
  }
}
