$color: var(--spinner-color, #7078a3);

.spinner {
  z-index: 800;
  background-color: inherit;
}

.lds-ring {
  display: flex;
  position: absolute;
  width: 40px;
  height: 40px;
  align-self: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 8px;
  border: 3px solid $color;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $color transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ring-qr-code {
  display: flex;
  position: absolute;
  width: 40px;
  height: 45px;
  align-self: center;
  justify-content: center;
  z-index: 10;
  // top: 28px;
  // left: 30px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 33px;
    height: 33px;
    margin: 8px;
    border: 3px solid $color;
    border-radius: 50%;
    animation: lds-ring-qr-code 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring-qr-code {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.lds-ring-two-factor {
  display: flex;
  position: absolute;
  width: 50px;
  height: 50px;
  align-self: center;
  justify-content: center;
  top: 30px;
  left: 40px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 8px;
    border: 3px solid $color;
    border-radius: 50%;
    animation: lds-ring-two-factor 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring-two-factor {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.lds-ring-fee {
  display: flex;
  position: absolute;
  width: 20px;
  height: 20px;
  align-self: center;
  justify-content: center;
  top: -16px;
  left: -9px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    margin: 8px;
    border: 1px solid $color;
    border-radius: 50%;
    animation: lds-ring-fee 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring-fee {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.lds-ring-button {
  display: flex;

  width: 16px;
  height: 16px;

  align-self: center;
  justify-content: center;
  align-items: center;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 13px;
    height: 13px;

    border: 1px solid $color;
    border-radius: 50%;
    animation: lds-ring-fee 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ffffff transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring-fee {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.lds-ring-slots {
  display: flex;
  position: absolute;
  width: 50px;
  height: 50px;
  align-self: center;
  justify-content: center;

  // top: 55px;
  // left: 42px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 8px;
    border: 3px solid $color;
    border-radius: 50%;
    animation: lds-ring-slots 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring-slots {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.in-play-ring-slots {
  display: flex;
  position: relative;
  width: 20px;
  height: 20px;
  align-self: center;
  justify-content: center;
  top: -10px;
  left: 0px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 8px;
    border: 2px solid $color;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes in-play-ring-slots {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
