@import "../variables.module";

.provider-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    border: 1px solid rgba($color: #FFFFFF, $alpha: 0.05);
    max-width: 290px;
    max-height: 44px;
    background: radial-gradient(
      ellipse farthest-corner at 50% 100%,
      #262c51,
      #1d2341
    );
    border-radius: 6px;
    // border: 1px solid #282f58;
    font-weight: 500;
    cursor: pointer;
  
    .close-btn {
      position: absolute;
      top: -25px;
      right: -30px;
      color: #D1D2DC;
    }
    .provider-dropdown-container {
      position: absolute;
      width: 100%;
      top: 50px;
      right: 0;
      background-color: #262c51;
      z-index: 10;
      border-radius: 6px;
      transition: opacity 0.5s;
      input[name=search] {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    
    }

    .first-label {
      font-size: 13px;
      color: rgba(232, 229, 255, 0.75);
      white-space: nowrap;
      cursor: pointer;
      pointer-events: none;
    }
    .provider-toggle-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
    }
    .label-container {
      position: absolute;
      left: 14px;
      display: flex;
      align-items: center;
      gap: 8px;
      width: calc(100% - 50px);
    }
    .provider-dropdown-label {
      color: $white;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
    }   
    .provider-dropdown-scroll-container {
    
      // max-height: 343px;
      overflow-y: hidden;
      position: relative;

      .provider-dropdown-checkbox {
        margin: 0;
        width: 16px;
        height: 16px;
      }
    }
    .provider-select-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 8px;
      align-items: center;
      padding: 14px 24px 14px 16px;
      background-color: "transparent" ;
      border: 1px solid rgba(#7C83B1, 0.1);

      cursor: pointer;
      .label {
          width: 100%;
          font-size: 13px;
          color: $white;
          cursor: pointer;
      }
      .count {
          color: rgba($color: #FFFFFF, $alpha: 0.5);
          font-size: 12px;
      }
      &:hover {
          background: linear-gradient(rgba(#7C83B1, 0.1), rgba(#626BA2, 0.1));
      }
      &.btn-active{
          background: linear-gradient(rgba(#7C83B1, 0.25), rgba(#626BA2, 0.25));
      }
    }
}

@media only screen and (max-width: 1280px) {
  .provider-dropdown {
    width: 100%;
    max-width: 100%;
  }
}