@import "../variables.module";

.drop-down-container {
  // position: fixed;
  width: auto;

  // height: 500px;
  // width: 135px;
  // overflow: hidden;
  border-radius: $radius;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 10;

  // border: 1px solid #fff;
  // padding: 10px;

  // display: flex;
}

.drop-down-container.active {
  max-height: 1000px;
}

.expand-container {
  width: 100%;
}

.register-modal {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-out;
}

@media (max-width: $breakpoint-one) {
}

@media (prefers-color-scheme: dark) {
}

.dropdownContainer {
  color: rgba(232, 229, 255, 0.7);
  font-size: 12px;
  display: flex;
  align-items: center;
}
.dividingLine {
  height: 1px;
  width: 100%;
  background: rgba(232, 229, 255, 0.08);
  margin-right: 15px;
}

.dropdownLabel {
  cursor: pointer;
  padding: 0 15px 0 0;
  text-wrap: nowrap;
  font-weight: 500;

  span {
    color: rgba(232, 229, 255, 0.75);
    font-style: italic;
  }
}

.arrowButton {
  display: inline;
  & > button {
    padding: 0 0 0 0px !important;
    border: 0;
    background-color: transparent !important;
    z-index: 40;
  }
}
