@import "../variables.module";

.how-it-works {
  max-width: 420px;

  .crown {
    margin-right: 8px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    p {
      font-weight: 600;
      font-size: 17px;
    }
  }

  p {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 14px;
    color: #b2b1c9;

    &:last-child {
      margin: 0;
    }
  }
}

.races {
  .content {
    display: flex;
    gap: 26px;
    margin-top: 32px;
    font-size: 13px;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.15rem;
    color: $primaryFontColor;

    @media (max-width: $breakPointTwo) {
      flex-wrap: wrap;
    }

    h3 {
      font-size: 14px;
      color: $primaryFontColor;
    }

    .table {
      flex-grow: 1;
    }

    .stats {
      width: 260px;
      padding: 24px;
      background: radial-gradient(
        at center bottom,
        #262c52 -100%,
        #0f1328 100%
      );
      border: 1px solid rgba($color: #5e679e, $alpha: 0.3);
      border-radius: $radius;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (max-width: $breakPointTwo) {
        width: 100%;
        margin: 0 auto;
        order: -1;
      }

      .stat {
        width: 100%;
        height: 45px;
        padding: 14px;
        border-radius: $radius;
        background: rgba($color: #5e679e, $alpha: 0.04);
        border: 1px solid rgba($color: #5e679e, $alpha: 0.3);
        display: flex;
        align-items: center;
        // elipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        gap: 10px;

        > span {
          color: rgba($color: #e8e5ff, $alpha: 0.75);
        }
      }

      .next-stat {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 0.25rem;

        @media (max-width: $breakPointTwo) {
          margin-top: 1rem;
        }

        > span {
          display: flex;
          align-items: center;
          justify-self: center;
          color: rgba($color: #e8e5ff, $alpha: 0.75);
        }
      }
    }
  }

  .table {
    text-align: center;
    font-weight: 400;
    display: flex;
    flex-direction: column;

    .gold {
      color: $gold;

      &--shadow {
        @extend .gold;
        text-shadow: 0px 0px 10px $gold;
      }
    }

    .gold-color span {
      color: $gold;
    }

    .diamond {
      color: $diamond;
    }

    .platinum {
      color: $platinum;
    }

    .bronze {
      color: $bronze;
    }

    .head {
      font-size: 13px;
      font-weight: 500;
      line-height: 17px;
      margin-bottom: 16px;
      display: flex;
      width: 100%;

      .th {
        color: $fontColorSeven;
        flex: 1;

        &:nth-child(1) {
          margin-left: 1%;
          width: 60px;
          flex: unset;
        }

        &:nth-child(2) {
          justify-content: flex-start;
          padding-left: 3%;
          text-align: left;
        }

        &:last-child {
          justify-content: flex-end;
          padding-right: 2%;
          text-align: right;
        }
      }
    }

    .tbody {
      display: flex;
      flex-direction: column;

      .tr {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 60px;
        background: #24294d59;
        margin-bottom: 8px;
        border-radius: $radius;

        &.highlighted {
          background: linear-gradient(
            rgba($color: $gold, $alpha: 0.1),
            rgba($color: $gold, $alpha: 0.3) 100%
          );
          outline: 1px solid $gold;
        }
      }

      .td {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: white;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: -4px;
          transform: translateY(1.5px);
        }

        &:nth-child(1) {
          margin-left: 1%;
          width: 60px;
          flex: unset;
        }

        &:nth-child(2) {
          justify-content: flex-start;
          padding-left: 3%;
          text-align: left;
        }

        &:last-child {
          justify-content: flex-end;
          padding-right: 2%;
          text-align: right;
        }

        .rank-wrapper {
          display: inline-flex;
          align-items: center;
          min-height: 100%;
          gap: 8px;

          > div:first-child {
            display: flex;
            align-items: center;

            @media (max-width: 470px) {
              display: none;
            }
          }
        }

        .rank {
          width: 100%;
          height: 100%;
          text-align: left;

          @media (max-width: $breakPointOne) {
            margin-top: 4px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .banner {
    position: relative;
    background-image: url("/Images/races_bg.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    width: 100%;
    min-height: 290px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 24px 32px;
    border-radius: 11px;

    overflow: visible;

    @media screen and (max-width: 1371px) {
      flex-direction: column;
    }

    @media screen and (max-width: 805px) {
      background-position: 85%;
    }

    > div {
      @media (max-width: $breakPointOne) {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    // logo panel
    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .heading {
      display: flex;
      flex-direction: column;
      gap: 24px;
      color: white;
      text-align: center;
      font-size: 16px;
      font-style: italic;
      font-weight: 600;
      line-height: 20px;
    }

    .number-card-wrapper {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;

      @media (max-width: 450px) {
        gap: 8px;
      }

      .number-card {
        width: 72px;
        height: 84px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: 16px 12px;

        border-image-width: 1;

        border-image-slice: 1;
        border-image-source: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 193, 86, 1) 100%
        );

        border-radius: 6px;
        border: 2px solid #ffd58c;
        background:
          radial-gradient(
            76.33% 100% at 50% 100%,
            rgba(255, 167, 15, 0.5) 0%,
            rgba(255, 193, 86, 0) 100%
          ),
          #5a504c;
        box-shadow: 2px 2px 60px 0px rgba(251, 220, 174, 0.3);

        // intentional
        @media (max-width: 450px) {
          width: 60px;
          height: 72px;
          padding: 12px 8px;
        }

        .number {
          font-size: 24px;
          font-weight: 700;
          color: #ffffff;

          @media (max-width: 450px) {
            font-size: 20px;
          }
        }

        .text {
          font-size: 14px;
          font-weight: 700;
          color: #e8e5ffbf;
          @media screen and (max-width: 450px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .banner::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 13px;
    z-index: -1;
    background: linear-gradient(
      to bottom,
      rgba(255, 193, 86, 0) 0%,
      rgba(255, 193, 86, 0.8) 50%,
      rgba(255, 193, 86, 1) 100%
    );
  }

  .prize-wrapper {
    display: flex;
    align-items: center;
    font-style: italic;

    .prize {
      font-weight: 700;
      text-shadow:
        1.366px 1.366px 0px #fbdcae,
        0px 10.925px 10.925px rgba(0, 0, 0, 0.25),
        0px 0px 76.176px rgba(219, 145, 0, 0.3);
      -webkit-text-stroke-width: 2.185054302215576;
      -webkit-text-stroke-color: #000;

      font-size: 45px;
      font-style: italic;
      line-height: 100%; /* 45px */
      text-transform: uppercase;
      background: linear-gradient(181deg, #fff 14.35%, #f5f5f5 99%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: $breakPointOne) {
        font-size: 45px;
      }
    }

    .text {
      margin-left: 14px;
      margin-bottom: -4px;
      padding-right: 4px;
      text-shadow:
        0px 9.074px 9.074px rgba(0, 0, 0, 0.25),
        0px 0px 36.963px rgba(251, 220, 174, 0.6);
      -webkit-text-stroke-width: 0.7580902576446533;
      -webkit-text-stroke-color: #000;
      font-size: 32px;
      font-style: italic;
      font-weight: 800;
      line-height: 100%; /* 24.259px */
      text-transform: uppercase;
      background: linear-gradient(180deg, #ffdda1 32%, #f69c01 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: $breakPointOne) {
        font-size: 24px;
        text-wrap: nowrap;
      }
      @media (max-width: 450px) {
        font-size: 21px;
        text-wrap: wrap;
      }

      @media (max-width: 409px) {
        font-size: 18px;
      }

      hr {
        width: 100%;
        height: 3px;
        border: 0;
        border-radius: 6px;
        background: linear-gradient(
          89.75deg,
          rgba(230, 189, 119, 0) 0.52%,
          #e6bd77 101.1%
        );
        margin: inherit;
        margin-top: 4px;
      }
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 420px;

    padding: 16px 40px;
    border-radius: $radius + 4px;
    border: 1px;
    font-size: 14px;
    border: 1px solid rgba($color: #fff, $alpha: 0.1);
    color: #e8e5ff;
    background: rgba($color: #fff, $alpha: 0.05);

    transition: all 0.25s ease-in-out;

    &:hover {
      background: rgba($color: #fff, $alpha: 0.1);
    }

    @media (max-width: $breakPointOne) {
    }
  }
}
