@import "../variables.module";

.row {
    display: flex;
    
    justify-content: space-between;
    // border: 1px solid #000;
    gap: 20px;
    // margin-top: 10px;
    // margin-bottom: 10px;
    &.space-items {
        padding-bottom: 10px;
    }
    &.wrap-items {
        flex-wrap: wrap;
    }

    @media(max-width: $breakPointOne) {
        flex-direction: column;   
        gap: 0;
    }
   
}




//If there are more children, this classes gets added
// .space-items {
//     & div:nth-child(odd) {
//         margin-right: 10px;
//     }
//     & div:nth-child(even) {
//         margin-left: 10px;
//     }
// }

.col {
    display: flex;
    flex-direction: column;
}
.nav {

}

@media (max-width: $breakpoint-one) {
 
}

@media (prefers-color-scheme: dark) {
  
}
