@import "../variables.module";

$logo-width: 40%;

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: $secondaryBackgroundColor;
  border: 1px solid rgba($color: #fff, $alpha: 0.15);
  margin-right: 12px;
  transition: 0.2s all ease-in-out;
  margin-bottom: 16px;

  &:hover {
    border: 1px solid rgba($color: #fff, $alpha: 0.5);
  }
}

.gaming-badge {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  color: $fontColorSeven;
  gap: 16px;

  hr {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 16px;
  }
}

.anj-seal {
  &,
  * {
    width: auto !important;
    height: 41px !important;
  }
}

.link-wrapper {
  @apply flex flex-col gap-4 text-normal w-1/3;

  a {
    @apply text-grey-light pr-[10%] leading-normal;
  }
}

.mobile-links {
  @apply flex flex-col gap-4 text-normal;
}
