
.custom-checkbox {

    width: 16px;
    height: 16px;
    cursor: pointer;
    background: transparent;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid rgba($color: #ffffff, $alpha: 0.5);
    border-radius: 1.88px;
    margin: 0;

    &:checked {

        -webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto;
    }
}