@import "../variables.module";

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  gap: 10px;

  &.full-width {
    width: 100%;
  }

  &.default-width {
    width: auto;

    .figure {
      max-width: none;
    }
  }

  &.column-text {
    flex-direction: column;
  }

  .figure {
    max-width: 20%;
  }

  .text {
    max-width: 80%;
  }

  &.logo--shrink {
    .figure {
      @media (max-width: $breakPointThree) {
        max-width: 70%;
      }
    }

    .text {
      @media (max-width: $breakPointThree) {
        display: none;
      }
    }
  }
}
